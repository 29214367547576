import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Intro from '../components/Intro'
import Content from '../components/Content'

const Impressum = () => (
  <Layout>
    <SEO
      title="Impressum"
      keywords={[
        `Apps`,
        `Websites`,
        `Webseiten`,
        `Entwicklung`,
        `Programmierung`,
        `Homepage`,
        `Digital`,
      ]}
    />
    <Intro meta="Impressum" main={<>Verantwort&shy;lich für den Inhalt.</>} />
    <Content>
      <strong>A45 Anstalt</strong>
      <br />
      Äulestrasse 45
      <br />
      9490 Vaduz
      <br />
      Liechtenstein
      <br />
      +423 230 30 32
      <br />
      hallo@a45.li
      <br />
      <br />
      <strong>Handelsregister:</strong> FL-0002.004.798-6
      <br />
      <strong>MWST-Nr.:</strong> 54.532
      <br />
      <br />
      <strong>Vertretungsberechtigte Personen</strong>
      <br />
      Bernhard Wanger, Laura Wanger
      <br />
      <br />
      <strong>Haftungsausschluss</strong>
      <br />
      Eine Gewähr oder Garantie für die Aktualität, Richtigkeit und
      Vollständigkeit der zur Verfügung gestellten Informationen und Daten wird
      nicht übernommen. Dies gilt auch für alle anderen Websites, auf die direkt
      oder indirekt mittels Hyperlink verwiesen wird. Das Studio ist nicht
      verantwortlich für die Inhalte der externen Internetseiten. Wir haften
      nicht für Schäden materieller oder immaterieller Art, die durch die
      Nutzung der angebotenen Informationen verursacht werden.
      <br />
      <br />
      <br />
      <br />
    </Content>
  </Layout>
)

export default Impressum
